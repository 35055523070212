import { graphql, useStaticQuery } from "gatsby"

export const useSiteCareersI18N_IT = () => {
  const data: any = useStaticQuery(graphql`
    query {
      strapiCareer {
        localizations {
          data {
            attributes {
              title
              subtitle
              addressTitle
              openAppl
              mailto
            }
          }
        }
      }
      strapiFooter {
        address
      }
    }
  `)

  return data;

}