import { graphql, Link, StaticQueryDocument, useStaticQuery } from 'gatsby';
import * as React from 'react';
import { useEffect, useState } from 'react';
import DefaultAppView from '../app-view/default-app-view';
import { SEO } from '../components/seo';
import { getTheme, isMobile } from '../functions/theme';
import { useSiteStrapiCareersTitles } from '../hooks/site-strapi-careers';
import { SEOData } from '../constants/types';
import { getUserPreferredLocale } from '../functions/utils';
import { useSiteCareersI18N_IT } from '../hooks/site-strapi-i18n-IT/site-careers-i18n-IT';
import { useSiteCareersI18N_EN } from '../hooks/site-strapi-i18n-EN/site-careers-i18n-EN';

// --- CAREERS PAGE
const Careers = () => {

  const currentTheme: 'light' | 'dark' = getTheme();
  const _isMobile: boolean = isMobile();
  const _userLocale: string = getUserPreferredLocale();
    
  // --- Getting datas w/ querys from strapi 
  const strapiQuery: any = useSiteStrapiCareersTitles();
  const strapiI18NContent: any | undefined = (_userLocale.includes('it') || _userLocale.includes('IT')) ? useSiteCareersI18N_IT() : useSiteCareersI18N_EN();
  const careersContent = strapiI18NContent && (_userLocale.includes('it') || _userLocale.includes('IT')) ? strapiI18NContent?.strapiCareer.localizations.data[0].attributes : strapiI18NContent?.strapiCareer;
  const address: string = strapiI18NContent.strapiFooter.address;
  // ---

  const [currentUrl, setCurrentUrl] = useState<string>();

  const careersApplicationTitles = strapiQuery.map((el: any) => el.node)

  // Hook w/ update of current page url state
  useEffect(() => {
    setCurrentUrl(window?.location?.pathname); 
  }, [])

  return (
    <DefaultAppView currentUrl={currentUrl} userLocale={_userLocale}>
        <div className={`flex flex-col w-screen h-screen px-5 lg:px-36 pt-28 pb-16 lg:pt-40 ${currentTheme === 'light' ? 'bg-cardinal-white text-black' : 'bg-black text-cardinal-white'}`}>
          <div className='hidden capitalize lg:text-lg lg:flex'>{ careersContent.title }</div>
            <div className='flex flex-col w-full h-full lg:flex-row lg:pt-7'>
              <div className='flex flex-col w-full lg:h-full lg:w-2/5 lg:justify-between'>
                <span className='w-1/2 text-3xl capitalize lg:text-6xl'>{ careersContent.subtitle }</span>
                  <div className='flex flex-col w-1/2 pt-2 lg:pt-0'>
                    <span className='pb-1 text-[8px] uppercase lg:pb-7 lg:text-base'>{ careersContent.addressTitle }</span>
                    <span className='text-xs capitalize lg:text-xl'>{ address }</span>
                  </div>
              </div>
              <div className='flex flex-col justify-between w-full h-full pt-8 text-xl capitalize lg:text-2xl lg:w-3/5 lg:pt-0'>
                <div className='flex flex-col w-full'>
                {/* --- Mapping each open position | Every position linked to relative Linkedin page --- */}
                  { careersApplicationTitles && 
                    careersApplicationTitles.map((el: any, index: number) => {     
                    return ( el && 
                      <div key={index} className={`flex flex-row items-center justify-between w-full h-16 lg:h-32 border-y-[1px] group ${ currentTheme === 'light' ? 'border-black' : 'border-cardinal-white' }`}>
                        <Link to={`/career-detail/${el?.urlId}`} key={index +'linkPOS'} className='flex flex-row items-center justify-between w-full'>
                          <span key={index + 'namePOS'} className={`transition-[background-size] ease-in-out duration-200 cursor-pointer bg-[length:0%_0.1em] group-hover:bg-[length:100%_0.1em] group-focus:bg-[length:100%_0.1em] group-active:bg-[length:100%_0.1em] bg-no-repeat bg-left-bottom
                            ${ currentTheme == 'dark' ? 
                              'bg-gradient-to-r from-cardinal-white to-cardinal-white' : 
                              'bg-gradient-to-r from-black to-black'}`}>
                                { el?.title }
                          </span>
                          {/* - Arrow SVG - */}
                          <svg key={index + 'svgPOS'} xmlns="http://www.w3.org/2000/svg" width={_isMobile ? '8' : '16'} height={_isMobile ? '12' : '28'} viewBox="0 0 17.796 28.858">
                            <path key={index + 'pathPOS'} id="_Color" fill={currentTheme === 'light' ? '#222' : '#F5F4F5'} data-name=" ↳Color" d="M3.386,0,0,3.391,11,14.429,0,25.468l3.386,3.391L17.8,14.429Z"/>
                          </svg>
                        </Link>
                      </div>
                    )
                })}
                {/* --- */}
            </div> 
            {/* --- Open position | Linked to Cardinal Linkedin page --- */}
            <div className={`flex flex-row items-center justify-between w-full h-16 lg:h-32 border-y-[1px] ${ currentTheme === 'light' ? 'border-black' : 'border-cardinal-white' }`}>
              <a href={careersContent.mailto} target='_blank' className='flex flex-row items-center justify-between w-full group'>
                <span className={`transition-[background-size] ease-in-out duration-200 uppercase cursor-pointer bg-[length:0%_0.1em] group-hover:bg-[length:100%_0.1em] group-focus:bg-[length:100%_0.1em] group-active:bg-[length:100%_0.1em] bg-no-repeat bg-left-bottom
                  ${currentTheme == 'dark' ? 
                    'bg-gradient-to-r from-cardinal-white to-cardinal-white' : 
                    'bg-gradient-to-r from-black to-black'}`}>
                      { careersContent.openAppl }
                </span>
                {/* - Arrow SVG - */}
                <svg xmlns="http://www.w3.org/2000/svg" width={_isMobile ? '8' : '16'} height={_isMobile ? '12' : '28'} viewBox="0 0 17.796 28.858">
                  <path id="_Color" fill={currentTheme === 'light' ? '#222' : '#F5F4F5'} data-name=" ↳Color" d="M3.386,0,0,3.391,11,14.429,0,25.468l3.386,3.391L17.8,14.429Z"/>
                </svg>
              </a>
            </div>
            {/* --- */}
          </div>
        </div>
      </div>
    </DefaultAppView>
  )

}

export default Careers;

// ----------------------- SEO CREATION ---------------------- //

export const seoData: StaticQueryDocument | undefined = graphql`
  query {
    strapiCareer {
      SEO {
        metaTitle
        metaDescription
        keywords
        preventIndexing
        pathname
      }
    }
  }
`

export const Head = () => {
  const seo: SEOData = seoData && useStaticQuery(seoData).strapiCareer.SEO;

  return (
    <SEO 
      title={seo.metaTitle} 
      description={seo.metaDescription} 
      keywords={seo.keywords} 
      preventIndexing={seo.preventIndexing} 
      pathname={seo.pathname}/>   
  )
}
