import { graphql, useStaticQuery } from "gatsby"

export const useSiteCareersI18N_EN = () => {
  const data: any = useStaticQuery(graphql`
    query {
      strapiCareer {
        title
        subtitle
        addressTitle
        openAppl
        mailto
      }
      strapiFooter {
        address
      }
    }  
  `)

  return data;

}